<template>
  <div class="container mw-fit-content">
    <div class="w-100" style="max-width: 200px">
      <div
        class="form-group" >
        <label for="">Uygulama Seçiniz</label>
        <Multiselect
          v-model="appId"
          :options="applist"
          label="name"
          track-by="name"
          placeholder=""
          selectLabel=""
          selectedLabel=""
          deselectLabel=""
        ></Multiselect>
      </div>
    </div>
    <Table
      :thead="thead"
      :data="data"
      :search="false"
      :excel="false"
      :searchBox="true"
      tableTitle="Bildirimler"
    >
      <template v-slot:cell-label="{ row: data }">
        {{ data.label }}
      </template>
      <template v-slot:cell-title="{ row: data }">
        {{ data.title }}
      </template>
      <template v-slot:cell-message="{ row: data }">
        {{ data.message }}
      </template>
    </Table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: [],
      applist: [],
      appId: "",
    };
  },
  created() {
    this.getApp();
    this.$store.commit("theme/addToBreadcrumps", [
      {
        title: "Anasayfa",
        path: "/",
      },
      {
        title: "Bildirimler",
        path: "/notifications",
      },
    ]);
  },
  watch: {
    appId: function () {
      this.getNotifications();
    },
  },
  methods: {
    getApp() {
      this.$axios.get("/mobile-app/").then((response) => {
        let apps = response.data;
        apps.filter((app) => {
          this.applist.push({
            id: app.id,
            name: app.name,
          });
          this.appId = this.applist[0]
        });
      });
    },
    getNotifications() {
      this.$axios
        .get(`/mobile-app/${this.appId.id}/notification/`)
        .then((response) => {
          const notifications = response.data;
          this.data = []
          notifications.filter((notification) => {
            this.data.push({
              id: notification.id,
              label: notification.label,
              title: notification.title,
              message: notification.message,
            });
          });
        });
    },
    handleText(data) {
      let keynames = data.keyName.split(".");
      let target = this; // Başlangıç noktası, this (genellikle Vue bileşeni)

      for (let i = 0; i < keynames.length; i++) {
        if (i === keynames.length - 1) {
          target[keynames[i]] = data.value; // Son adımda data.value'yu atama
        } else {
          if (!target[keynames[i]]) {
            target[keynames[i]] = {}; // Eğer yol henüz tanımlanmamışsa boş bir nesne oluşturma
          }
          target = target[keynames[i]]; // Yolu takip et
        }
      }
    },
  },
  setup() {
    const thead = [
      {
        label: "Bildirim Adı",
        value: "label",
        searchable: true,
      },
      {
        label: "Bildirim Başlığı",
        value: "title",
        searchable: true,
      },
      {
        label: "Bildirim Mesajı",
        value: "message",
        searchable: true,
      },
    ];
    return {
      thead,
    };
  },
};
</script>